:root {
  --font01: 'poppins';
  --main-font-family: var(--font01), 'roboto', 'Helvetica Neue', sans-serif;
  --font-weight-500: 500;
  --fontSizeField: 0.938rem;
  --fontSizeForError: 0.703rem;
  --fontWeightForError: 500;
  --fontSizeForLink: 0.875rem;
  --fontSizeForSectionTitle: 1.125rem;
  --fontSizeForSectionSubTitle: 0.938rem;
  --fontSizeForBubble: 0.625rem;
  --fontSizeForListTitle: 0.813rem;
  --fontSizeForListSentence: 0.75rem;
  --fontLetterSpacing: 0.026em;
  --TdFontSizeForListTitle: 0.938rem;
  --TdFontSizeForSectionSubTitle: 0.813rem;
}

.gral-cont {
  &.poppins {
    * {
      font-family: 'poppins' !important;
    }
  }
  &.roboto {
    * {
      font-family: 'roboto' !important;
    }
  }
  &.montserrat {
    * {
      font-family: 'montserrat' !important;
    }
  }
  &.mulish {
    * {
      font-family: 'Mulish', sans-serif !important;
    }
  }
  &.petrona {
    * {
      font-family: 'Petrona', serif !important;
    }
  }
  &.spectral {
    * {
      font-family: 'Spectral', serif !important;
    }
  }

  &.jost {
    * {
      font-family: 'Jost', sans-serif !important;
    }
  }
  &.crimsonpro {
    * {
      font-family: 'Crimson Pro', serif !important;
    }
  }
  &.bitter {
    * {
      font-family: 'Bitter', serif !important;
    }
  }

  &.opensans {
    * {
      font-family: 'Open Sans', sans-serif !important;
    }
  }
  &.raleway {
    * {
      font-family: 'Raleway', sans-serif !important;
    }
  }
}
