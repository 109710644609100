:root {
  --appBackground: #FFFFFF; // 00
  --primaryColor: #FF9500; // 01
  --primaryAccentColor: #404040; // 02
  --secondaryAccentColor: #535353; // 03
  --tertiaryAccentColor: #9E9E9E; // 04
  --backgroundShade: #DADADA; // 05
  --accentBackground: #F0F4F6; // 06
  --headerBackground: #F7F7F7; // 07
  --inactiveButton: #F2F2F2; // 08
  --alertsErrors: #FF0000; // 09
  --checkbox: #3369FF; // 10
  --progressBar: #00BB66; // 11
  --webInitialBackground: #E7E7E7; //
  --primaryColorSemiTransparent: #FFF4E6; // 98
  --appCameraBackground: #000000; // 99
  --successBar: #00BB00;
}
