@import 'src/scss/functions';

$info-request-item-button-columns-xlarge: 9;
$info-request-item-button-columns-large: 6;
$info-request-item-button-padding: 16px;
$info-request-item-button-size: 102px;
$info-request-item-button-column-width-xlarge: getInfoRequestButtonGridWidth(
  $info-request-item-button-columns-xlarge,
  $info-request-item-button-padding,
  $info-request-item-button-size
);
$info-request-item-button-column-width-large: getInfoRequestButtonGridWidth(
  $info-request-item-button-columns-large,
  $info-request-item-button-padding,
  $info-request-item-button-size
);

:root {
  --border-radius: 0.813rem;
  --navbar-height: 3.125rem;
  --header-height: 7.188rem;
  --main-content-template-padding: 1.094rem;
  --max-with-for-dialog-info: 31.25rem;
  --gap-for-flex: 0.5rem;
  --gap-for-column-grid: 0.625rem;
  --gap-for-row-grid: 0.625rem;
  --global-padding-right: 1.4em;
  --global-padding-left: 1.4em;
  --global-margin-rigth: 0.625rem;
  --global-margin-left: 0.625rem;
  --global-padding-top: 1.25em;
  --global-padding-bottom: 1.25em;
}
